import { NextPage } from 'next'
import { useRef } from 'react'
import style from './home.module.css'
import { Suggested } from './comp/suggested/Suggested'
import { Shelf } from '@/components/shelf/Shelf'
import { useMobile } from '@/hooks'
import {
  TAd,
  Element,
  TMusic,
  TCategoryType,
  TShelfOrder,
  TArtistsOrder,
  TArtist,
  TPlaylist,
  TAlbum,
} from '@/types'
import { useGetArtistsQuery, useGetRecommendationsQuery } from '@/store'

export type TFinalData = {
  title: string
  enTitle: string
  data?: TMusic[] | TArtist[] | TPlaylist[] | TAlbum[] | TAd | null
  loading?: boolean
  isAd?: boolean
  linkToMoreList?: string
  type?: TCategoryType
  displayShowAll?: boolean
  query?: string
}

type THomeProps = {
  shelfOrder: Array<TShelfOrder>
  artistsOrder: TArtistsOrder
}

export const Home: NextPage<THomeProps> = (): Element => {
  const ref = useRef(null)
  const isMobile = useMobile()
  const height = isMobile ? 200 : 256

  const { data: finalData, isLoading } = useGetRecommendationsQuery('')
  const { data: artistList, isLoading: isArtistLoading } = useGetArtistsQuery({
    limit: 6,
  })

  // const { currentData: { items: artistList = [] } = {} } =
  //   useGetArtistsWithQueryQuery(
  //     `?${props?.artistsOrder?.query ?? ''}${
  //       props?.artistsOrder?.uuids ? '&uuid=' + props?.artistsOrder?.uuids : ''
  //     }`,
  //   )

  // const mapper: Record<string, TMusic[] | TArtist[] | TPlaylist[] | TAlbum[]> =
  //   {}

  // const apiMapper = {
  //   track: useGetSongsWithQueryQuery,
  //   artist: useGetArtistsWithQueryQuery,
  //   album: useGetAlbumsWithQueryQuery,
  //   playlist: useGetPlaylistWithQueryQuery,
  // }

  // for (const key in props.shelfOrder) {
  //   if (Object.prototype.hasOwnProperty.call(props.shelfOrder, key)) {
  //     const element = props.shelfOrder[key] || {}

  //     const uuids = element?.uuids ?? ''
  //     const query = element?.query ?? ''

  //     const { currentData: { items = [] } = {} } = apiMapper[
  //       element.type || 'track'
  //     ](`?${query}${uuids ? '&uuid=' + uuids : ''}`)
  //     mapper[element.enTitle] = items
  //   }
  // }

  // const shelfAd = useAppSelector(selectAllHomeAd)
  // const sliderAdList = useAppSelector(selectAllHomeSliderAd)

  // const [executeSongLastPlayedList, { data: dataLastPlayedList }] =
  //   useLazyGetSongLastPlayedListQuery()

  // useEffect(() => {
  //   if (isLogin) {
  //     executeSongLastPlayedList(filters.lastPlayed)
  //   }
  // }, [isLogin])

  // useEffect(() => {
  //   dispatch(setSongToAllData([]))
  // }, [])

  // const finalData = useMemo<Array<TFinalData>>(() => {
  //   const array: TFinalData[] = props?.shelfOrder
  //     .filter((x) => x.enTitle !== 'recently')
  //     .map(({ enTitle, title = '', query, type = 'track' }) => {
  //       return {
  //         data: mapper[enTitle as keyof typeof mapper],
  //         loading: false,
  //         title,
  //         enTitle,
  //         type,
  //         query,
  //       }
  //     })

  //   if (isLogin) {
  //     const recentlyIndex = props?.shelfOrder?.findIndex(
  //       (x) => x.enTitle === 'recently',
  //     )
  //     array.splice(recentlyIndex, 0, {
  //       data: dataLastPlayedList?.items,
  //       title: String(props?.shelfOrder?.[recentlyIndex]?.title),
  //       enTitle: 'recently-played',
  //       loading: false,
  //       linkToMoreList: '/recently',
  //     })
  //   }

  //   if (shelfAd && shelfAd?.length > 0) {
  //     shelfAd?.forEach((item) => {
  //       const temp = {
  //         data: {
  //           ...item.extra.data,
  //           image_url: `${process.env.NEXT_CONTENT_URL}${item.file_path}`,
  //         } as TAd,
  //         title: item.name,
  //         enTitle: '',
  //         loading: false,
  //         isAd: true,
  //       }
  //       array.splice(Number(item?.extra?.section_id), 0, temp as TFinalData)
  //     })
  //   }

  //   return array
  // }, [dataLastPlayedList, mapper])

  return (
    <div className={style.wrapper} ref={ref}>
      <div
        style={{
          minHeight: height,
        }}
      >
        {artistList?.results && (
          <Suggested artists={artistList?.results} advertisements={[]} />
        )}
      </div>

      {finalData?.map((item, index) => {
        // if (item.isAd) return <InlineAd key={index} data={item.data as TAd} />
        return (
          <Shelf
            key={index}
            data={item.songs as Array<TMusic | TArtist | TAlbum | TPlaylist>}
            title={item.category.name}
            enTitle={item.category.name_en}
            category={item.category}
            query={''}
            type={'song'}
            loading={isLoading}
            // linkToMoreList={item.linkToMoreList}
          />
        )
      })}
    </div>
  )
}

export default Home
