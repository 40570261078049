import { m } from 'framer-motion'
import { useMemo } from 'react'
import { SuggestedItem } from './SuggestedItem'
import { UI_Carousel, TSlider, UI_Typography } from '@/ui'
import { Element, TArtist, TAdvertisement } from '@/types'
import { useMobile, useTranslate } from '@/hooks'
import { TGreeting, greeting } from '@/tools'
import { TranslationKey } from '@/tools/flattenMessages'
import { isSSR } from '@/variables'

type TSuggestedProps = {
  artists: Array<TArtist>
  advertisements: Array<TAdvertisement>
}

export const Suggested = (props: TSuggestedProps): Element => {
  const { artists, advertisements } = props
  const { translate } = useTranslate()

  const isMobile = useMobile()

  const moment = greeting()

  const greetingText: Record<TGreeting, TranslationKey> = {
    afternoon: 'global.good-afternoon',
    morning: 'global.good-morning',
    night: 'global.good-night',
  }

  const SlidesData: TSlider[] = useMemo(
    () =>
      [
        {
          id: 1,
          title: 'یک',
          image_url: '/images/subscriptions/sub_banner_mobile.png',
        },
        {
          id: 2,
          title: 'دو',
          image_url: '/images/subscriptions/sub_banner_mobile.png',
        },
      ] as TSlider[],
    [],
  )

  const width = isMobile ? (isSSR ? 456 : window.innerWidth - 32) : 456
  const height = isMobile ? 200 : 256

  return (
    <m.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
      }}
      className='section-4'
    >
      {!isMobile && (
        <UI_Typography variant='enDesktopTitle2' component='h4'>
          {translate(greetingText[moment])}
        </UI_Typography>
      )}
      <div className='flex flex-wrap justify-start gap-5 w-full'>
        <div className='lg:flex-none flex-1 lg:w-[450px] md:w-[450px] w-full aspect-auto'>
          <UI_Carousel
            width={width}
            height={height}
            carouselClassName='w-full'
            slides={SlidesData}
          />
        </div>
        {!isMobile && (
          <div className='w-full flex-1 flex flex-wrap -mx-2 -my-1'>
            {artists?.map((item) => (
              <SuggestedItem key={item.id} {...item} />
            ))}
          </div>
        )}
      </div>
    </m.section>
  )
}
