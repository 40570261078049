import Link from 'next/link'
import { UI_Image, UI_Typography } from '@/ui'
import { imageUrl } from '@/tools'
import { Element, TArtist } from '@/types'
// import { useLocale } from '@/hooks'

export const SuggestedItem = (props: TArtist): Element => {
  const { uuid, id, name: nickname, image } = props
  // const { isPersian } = useLocale()
  return (
    <Link href={`/artist/${id}`} className='w-1/2 px-2 py-1'>
      <div className='flex items-center gap-2 bg-cards-glass-primary rounded-s-[50px]'>
        <div className='flex flex-center'>
          <UI_Image
            className='rounded-lg object-cover'
            alt='artist image'
            src={image ? image : '/images/artist-default.jpg'}
            width={80}
            height={80}
          />
        </div>
        <UI_Typography
          variant='enBodyBoldText3'
          className='ellipsis max-w-[150px]'
          component='div'
        >
          {nickname}
        </UI_Typography>
      </div>
    </Link>
  )
}
