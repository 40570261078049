import { TAppProps } from './_app'
import Header, { HeaderHomeMobile } from './global'
import { Home as HomePage } from '@/containers/home/Home'
import {
  getRunningArtistsQueriesThunk,
  wrapper,
  getRunningSongQueriesThunk,
  getSongsWithQuery,
  getArtistsWithQuery,
} from '@/store'
import { Element, TArtistsOrder, TShelfOrder } from '@/types'
import { Seo } from '@/components'
import { useTranslate } from '@/hooks'

const Page: TAppProps['Component'] = (props): Element => {
  const { translate } = useTranslate()
  return (
    <>
      <Seo description={translate('home.seo-description')} />
      <HomePage {...props} />
    </>
  )
}

Page.header = <Header />
Page.mobileHeader = <HeaderHomeMobile />

export default Page

export const artistOrderList = {
  query: '$sortby=id&$limit=7',
}

export const shelfOrderList: Array<TShelfOrder> = [
  {
    enTitle: 'new_music',
    uuids: [
      'ed315404-9286-418e-aef7-a9cc95e40b42',
      '2e253314-bace-4b33-b976-f9b8122c2afc',
    ],
    query: '$sortby=id&$limit=7',
    title: 'english title',
  },
  {
    enTitle: 'moharram',
  },
  {
    enTitle: 'recently',
  },
  {
    enTitle: 'sonati',
  },
  {
    enTitle: 'navahi',
  },
  {
    enTitle: 'emam_reza',
  },
  {
    enTitle: 'monthly_top_songs',
  },
  {
    enTitle: 'weekly_top_songs',
  },
  {
    enTitle: 'top_tracks',
  },
]

export const getIdsFilter = (
  data: Array<TShelfOrder> = [],
  type: string,
): string[] | undefined => {
  return data?.find((x) => x.enTitle === type)?.uuids
}

export const getStaticFilters = (
  data: Array<TShelfOrder> = [],
  type: string,
): string | undefined => {
  return data?.find((x) => x.enTitle === type)?.query
}

export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async () => {
    const artistsOrder =
      (store?.getState()?.settings?.config?.artistsOrder as TArtistsOrder) ||
      artistOrderList

    store.dispatch(
      getArtistsWithQuery.initiate(
        `?${artistsOrder?.query ?? ''}${
          artistsOrder?.uuids ? '&uuid=' + artistsOrder?.uuids?.join(',') : ''
        }`,
      ),
    )

    const shelfOrder =
      (store?.getState()?.settings?.config?.shelfOrder as Array<TShelfOrder>) ||
      shelfOrderList

    for (const key in shelfOrder) {
      if (Object.prototype.hasOwnProperty.call(shelfOrder, key)) {
        const element = shelfOrder[key] || {}
        const uuids = element?.uuids
        const query = element?.query

        store.dispatch(
          getSongsWithQuery.initiate(
            `?${query ?? query}${uuids ? '&uuid=' + uuids : ''}`,
          ),
        )
      }
    }

    await Promise.all(store.dispatch(getRunningArtistsQueriesThunk()))
    await Promise.all(store.dispatch(getRunningSongQueriesThunk()))

    return {
      props: {},
    }
  },
)
